<template>
  <div class="ele-body" v-loading="loading">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flexspabet" @keyup.enter.native="$refs.table.reload()"
        @submit.native.prevent>
        <div style="margin-bottom: 10px;">
          <el-button @click="add()" size="small" v-if="permission.includes('sys:omapi:add')"
            class="ele-btn-icon addbtn ">添加设备
          </el-button>
        </div>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
        highlight-current-row stripe class="my-dialog-name">
        <template slot-scope="{index}">
          <el-table-column type="index" :index="index" label="序号" width="60px" align="center" show-overflow-tooltip />
          <el-table-column prop="address" label="地区" show-overflow-tooltip width="220px" />
          <el-table-column prop="om_id" label="设备ID" show-overflow-tooltip width="150" />
          <el-table-column prop="ip" label="IP地址" show-overflow-tooltip width="150" />
          <el-table-column prop="port" label="API端口" show-overflow-tooltip width="150" />
          <el-table-column prop="bucket" label="OSS bucket" show-overflow-tooltip width="150" />
          <el-table-column prop="operating_mode_versiont" label="推单模式" show-overflow-tooltip width="150" />
          <el-table-column label="运行状态" show-overflow-tooltip min-width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.status == 1 ? '在线' : '离线' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="create_time" label="添加时间" show-overflow-tooltip width="180px" />
          <el-table-column prop="jt_status" label="监听状态" show-overflow-tooltip min-width="100px">
            <template slot-scope="scope">
              <span>{{ scope.row.jt_status == 1 ? '监听中' : '未监听' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="oss_url" label="OSS设置" show-overflow-tooltip width="400px" />
          <el-table-column label="操作" width="400px" align="center" :resizable="false">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false">编辑
              </el-link>
              <el-link @click="stop(row, 1, index)" icon="el-icon-video-play" type="primary" :underline="false"
                v-if="permission.includes('sys:omapi:stop') && row.jt_status == 1 && isRouterAlive">停止监听
              </el-link>
              <el-link @click="stop(row, 2, index)" icon="el-icon-video-pause" type="primary" :underline="false"
                v-if="permission.includes('sys:omapi:stop') && row.jt_status == 0">监听
              </el-link>
              <el-link @click="remove(row)" icon="el-icon-delete" type="danger" :underline="false"
                v-if="permission.includes('sys:omapi:delete')">删除
              </el-link>
              <el-link @click="bohao_status(row)" icon="el-icon-phone" type="primary" :underline="false"
                v-if="permission.includes('sys:omapi:bohao')">拨号测试
              </el-link>
              <el-link @click="oss_status(row)" icon="el-icon-view" type="primary" :underline="false"
                v-if="permission.includes('sys:omapi:oss_status')">OSS状态测试
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 添加弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'编辑设备':'添加设备'" :visible.sync="showEdit" @closed="editForm={}"
      :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="150px">
          <div>
            <el-form-item label="地区：" prop="pname">
              <el-select v-model="editForm.pname" @change="handleChangeProv2(editForm.pname)" placeholder="请选择省"
                class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name">
                </el-option>
              </el-select>
              <el-select v-model="editForm.cname" @change="handleChangeCity2(editForm.cname)" placeholder="请选择市"
                class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name">
                </el-option>
              </el-select>
              <el-select v-model="editForm.aname" @change="$forceUpdate(),handleDis(editForm.aname)"
                placeholder="请选择区/县" class="selectStyle mr-10 mb-20" clearable>
                <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name">
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <el-form-item label="IP地址：" prop="ip">
            <el-input type="text" v-model="editForm.ip" aria-placeholder="请输入IP地址" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="设备ID：" prop="om_id">
            <el-input type="text" v-model="editForm.om_id" aria-placeholder="请输入设备ID" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="OSS设置：" prop="oss_url">
            <el-input type="text" v-model="editForm.oss_url" aria-placeholder="请输入OSS设置地址" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="设备密码：" prop="pwd">
            <el-input type="text" v-model="editForm.pwd" aria-placeholder="请输入设备密码" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="域名：" prop="voice_domain">
            <el-input type="text" v-model="editForm.voice_domain" aria-placeholder="请输入域名">
            </el-input>
          </el-form-item>
          <el-form-item label="音频存储路径：" prop="voice_url">
            <el-input type="text" v-model="editForm.voice_url" aria-placeholder="请输入音频存储路径">
            </el-input>
          </el-form-item>
          <el-form-item label="分机号码：" prop="voice_dir">
            <el-input type="text" v-model="editForm.voice_dir" aria-placeholder="请输入分机号码" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="API端口：" prop="port">
            <el-input type="text" v-model="editForm.port" aria-placeholder="请输入API端口" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="推单模式：" prop="port">
            <el-input type="text" v-model="editForm.operating_mode_version" aria-placeholder="请输入API端口" class="input216">
            </el-input>
          </el-form-item>
          <el-form-item label="OSS bucket：" prop="bucket">
            <el-input type="text" v-model="editForm.bucket" aria-placeholder="请输入OSS bucket" class="input216">
            </el-input>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">提交</el-button>
      </div>
      <audio src=""></audio>
    </el-dialog>
  </div>
</template>

<script>
  import {
    mapGetters
  } from 'vuex'

  export default {
    name: 'SysCountyCircle',
    components: {},
    provide() { // 父组件中返回要传给下级的数据
      return {
        reload: this.reload
      }
    },
    data() {
      return {
        timer: 0,
        isRouterAlive: true,
        table: {
          url: '/omapi/index',
          where: {}
        }, // 表格配置
        provArr: [],
        cityArr: [],
        districtArr: [],
        choose: [], // 表格选中数据
        showEdit: false, // 是否显示表单弹窗
        editForm: {
          voice_domain: 'http://uyl95128.oss-cn-beijing.aliyuncs.com',
          voice_dir: '103',
          port: '8989',
          bucket: 'uyl95128',
          voice_url: '/video/pbxrecord/pbxrecord/000EA92E2554/'
        }, // 表单数据
        userInfo: {},
        editRules: { // 表单验证规则
          pname: [{
            required: true,
            message: '请选择地区',
            trigger: 'change'
          }],
          ip: [{
            required: true,
            message: '请输入IP地址',
            trigger: 'blur'
          }],
          om_id: [{
            required: true,
            message: '请输入设备ID',
            trigger: 'blur'
          }],
          oss_url: [{
            required: true,
            message: '请输入OSS设置地址',
            trigger: 'blur'
          }],
          pwd: [{
            required: true,
            message: '请输入设备密码',
            trigger: 'blur'
          }]
        },
        editp: false,
        editc: false,
        edita: false,
   
        //wsOmUrl: 'ws://tuapi.livingtrip.uyl.cn:22053', //测试服
        wsOmUrl: 'wss://mpapi.livingtrip.uyl.cn/wss2', //正式服
      
        
        websock: {},
        player: '',
        wsuri: '',
        loading: false,
      }
    },
    created() {
      this.$http.get('/common/province_list').then(res => {
        let data = JSON.parse(res.data)
        this.provArr = data
      })
    },
    beforeRouteLeave(to, form, next) {
      next()
      //console.log('离开了页面')
      this.player = ''
      this.websock.close();
    },
    computed: {
      ...mapGetters(['permission']),
    },
    mounted() {
      this.getUserInfo()
    },
    methods: {
      reload() {
        this.isRouterAlive = false
        this.$nextTick(function() {
          this.isRouterAlive = true
        })
      },

      /* 获取当前用户信息 */
      getUserInfo() {
        this.$http.get(this.$setting.userUrl).then(res => {
          if (res.data.code === 0) {
            var info = JSON.parse(JSON.stringify(res.data.data))
            this.userInfo = info
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {
          this.$message.error(e.message)
        })
      },
      /**
       *选择省
       **/
      handleChangeProv(e) {
        /** 获取被选省份的pid**/
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.table.where.cid = ''
          this.table.where.area_id = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity(e) {
        if (e) {
          /** 获取被选市的cid**/
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.table.where.aid = ''
          })
        } else {
          this.table.where.pid = ''
          this.table.where.aid = ''
        }
      },
      /**
       *修改省
       **/
      handleChangeProv1(e) {
        /** 获取被选省份的pid**/
        var pid = ''
        this.provArr.forEach(function(item) {
          if (item.name == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          var data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      },
      /**
       *修改市
       **/
      handleChangeCity1(e) {
        if (e) {
          /** 获取被选市的cid**/
          var cid = ''
          this.cityArr.forEach(function(item) {
            if (item.name == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            var data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aname = ''
          })
        } else {
          this.editForm.pname = ''
          this.editForm.aname = ''
        }
      },
      add() {
        this.showEdit = true
        this.editForm = {
          voice_domain: 'http://uyl95128.oss-cn-beijing.aliyuncs.com',
          voice_dir: '103',
          port: '8989',
          bucket: 'uyl95128',
          voice_url: '/video/pbxrecord/pbxrecord/000EA92E2554/'
        }
      },
      /**
       * 显示编辑
       */
      edit(row) {
        this.editForm = row
        const loading = this.$loading({
          lock: true
        })
        if (this.editForm) {
          this.showEdit = true
          loading.close()
        }
      },
      // OSS状态测试
      oss_status(row) {
        const loading = this.$loading({
          lock: true
        })
        this.$http.post('/omapi/oss_test', {
          id: row.id
        }).then(res => {
          loading.close()
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.$refs.table.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {
          loading.close()
          this.$message.error(e.message)
        })
      },
      // 拨号测试
      bohao_status(row) {
        const loading = this.$loading({
          lock: true
        })
        this.$http.post('/omapi/status', {
          id: row.id
        }).then(res => {
          loading.close()
          if (res.data.code === 0) {
            this.$message({
              type: 'success',
              message: res.data.msg
            })
            this.$refs.table.reload()
          } else {
            this.$message.error(res.data.msg)
          }
        }).catch(e => {
          loading.close()
          this.$message.error(e.message)
        })
      },
      // 删除
      remove(row) {
        const loading = this.$loading({
          lock: true
        })
        this.$confirm('确定要删除选中的设备吗?', '提示', {
          type: 'warning'
        }).then(() => {
          this.$http.post('/omapi/delete', {
            id: row.id
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => {
          loading.close()
        });
      },
      /**
       * 保存编辑
       */
      save() {
        this.$refs['editForm'].validate((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true
            })
            if (this.editp) {
              this.editForm.pid = this.editForm.pname
            }
            if (this.editc) {
              this.editForm.cid = this.editForm.cname
            }
            if (this.edita) {
              this.editForm.aid = this.editForm.aname
            }

            this.$http.post('/omapi/edit', this.editForm).then(res => {
              loading.close()
              if (res.data.code === 0) {
                this.showEdit = false
                this.editForm = res.data.data
                this.$message({
                  type: 'success',
                  message: res.data.msg
                })
                this.$refs.table.reload()
              } else {
                this.$message.error(res.data.msg)
              }
            }).catch(e => {
              loading.close()
              this.$message.error(e.message)
            })
          } else {
            return false
          }
        })
      },
      // 停止监听
      stop(row, status, index) {
      
        //console.log('ttttt8888', status);
        if (status == 1) {
          this.destroyed()
        } else {
         let token = ''
         let wsuri = this.wsOmUrl + '?token=' + token + '&om_id=' + row.id + '&action=oss_voice';
       
         //this.wsuri = wsuri;
         this.initWebSocket(wsuri)
         this.editForm = row
         
          //this.loading = true;
         
        }
      },
      initWebSocket(wsuri) {
        this.websock = new WebSocket(wsuri)
        //console.log('creat websocket...',wsuri)
        this.websock.onmessage = this.websocketonmessage
        this.websock.onopen = this.websocketonopen
        this.websock.onerror = this.websocketonerror
        this.websock.onclose = this.websocketclose
      },
      // 连接建立之后执行send方法发送数据
      websocketonopen() {
        //console.log('om连接上了')
        this.editForm.jt_status = 1
        this.loading = false
        this.websockerRun();
      },
      //连接建立失败重连
      websocketonerror() {
        //console.log('连接失败，重新连接')
        this.$message.error('连接失败，可能是后端服务不可用，请稍后再试')
      },
      //数据接收
      websocketonmessage(e) {
        //console.log(e)
        var that = this

        if (e.data != '连接成功') {

          let js_data = JSON.parse(e.data)
          if (js_data.id == 'ping') {
            //console.log('ping')
            this.websock.send('{"type":"pong"}');
          } else {

            that.msgShow = true // 提示信息的弹框展示，弹框样式本文不再赘述
            that.msg = JSON.parse(e.data).msg // 前端弹框展示的内容为后端返回的信息，具体内容具体分析
            that.date = JSON.parse(e.data) // 前端弹框展示的内容为后端返回的信息，具体内容具体分析
            that.editForm.jt_status = 1
            //console.log('播放')
            // if (this.date.recording_remote && !that.player) {
            this.$http.post('/omapi/get_headers', {
              url: js_data.recording_remote
            }).then(res => {

              if (res.data.code == 1) {
                //console.log(res)
                this.stopPlay();
                that.player = document.createElement('audio');
                that.player.src = that.date.cut_audio
                //console.log('2222222222222222',that.player.src)

                that.player.id = 'audio'
                that.player.play()
                that.player.loop = false
                //console.log(this.player)
                this.player.addEventListener('ended', function() {
                  //console.log('播放完了')
                  //console.log(that.websock, '检查websocket')
                  that.websocketsend('{"id": "' + that.date.id + '"}')
                 this.stopPlay();
                }, false)
              } else {
                //console.log('播放22')
                that.websocketsend('{"id": "' + that.date.id + '"}')
                this.stopPlay();

              }
            })

            // }
          }
        }
      },
      websockerRun() {
        let that = this;
        this.timer = setInterval(() => {
          that.websocketsend({
            'id': 'ping'
          });

        }, 30000)

      },
      //数据发送
      websocketsend(Data) {
        //console.log(Data)
        //console.log(this.websock)
        if (this.websock) {
          this.websock.send(Data)
        }
      },
      stopPlay(){
        if(this.player){
          this.player.pause();
          this.player = null;
          //clearInterval(this.timer);
          
        }
        
      },
      //关闭 websocket
      websocketclose(e) {
        //console.log('断开连接了');
        this.stopPlay();
       // this.websock = null;
        this.editForm.jt_status = 0;
       
        
        //  return ;
        // this.player.pause();
        // // //console.log(this.player);
        // // this.player.p()
        // // //console.log(this.player);
        // // //console.log(document.getElementById('audio'));
        // this.player = null
        // //console.log(this.player);
        // this.$message.success('您已断开连接')
      },
      destroyed() {
        this.editForm.jt_status = 0
        if (this.websock) {
          clearInterval(this.timer);
          this.websock.close();
        }
      },
      handleChangeProv2(e) {
        /** 获取被选省省份的pid**/
        this.editp = true
        let pid = ''
        this.provArr.forEach(function(item) {
          if (item.pid == e) {
            pid = item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list', {
          pid: pid
        }).then(res => {
          let data = JSON.parse(res.data)
          this.cityArr = data
          /** 选择省份清空市县**/
          this.editForm.cname = ''
          this.editForm.aname = ''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity2(e) {
        if (e) {
          /** 获取被选市的cid**/
          this.editc = true
          let cid = ''
          this.cityArr.forEach(function(item) {
            if (item.cid == e) {
              cid = item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list', {
            cid: cid
          }).then(res => {
            let data = JSON.parse(res.data)
            this.districtArr = data
            /** 选择省份清空县**/
            this.editForm.aname = ''
          })
        } else {
          this.editForm.pname = ''
          this.editForm.aname = ''
        }
      },
      handleDis() {
        this.edita = true
      },
    }
  }
</script>

<style scoped>
  .ele-block>>>.el-upload,
  .ele-block>>>.el-upload-dragger {
    width: 100%;
  }

  /deep/ .el-dialog {
    margin-top: 30px !important;
  }
</style>
